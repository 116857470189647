export default [
  {
    title: 'Services',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'SBP',
        route: 'services-sbp',
      },
      {
        title: 'Property Rates',
        route: { name: 'misc-coming-soon' },
      },
    ],
  },
]
